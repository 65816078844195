@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100&family=Nova+Mono&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.absolute {
  position: absolute;
}

.bottom-10 {
  bottom: 2.5rem;
}

.right-\[5\%\] {
  right: 5%;
}

.top-\[\%5\] {
  top: % 5;
}

.-z-50 {
  z-index: -50;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.float-right {
  float: right;
}

.-m-\[55px\] {
  margin: -55px;
}

.m-5 {
  margin: 1.25rem;
}

.m-auto {
  margin: auto;
}

.-ml-\[5px\] {
  margin-left: -5px;
}

.-mr-\[5px\] {
  margin-right: -5px;
}

.-mt-\[100px\] {
  margin-top: -100px;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-\[130px\] {
  height: 130px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[90px\] {
  height: 90px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[17px\] {
  width: 17px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-full {
  width: 100%;
}

.max-w-\[1300px\] {
  max-width: 1300px;
}

.grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/5 {
  flex-basis: 20%;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.basis-full {
  flex-basis: 100%;
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -4px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animate-bounce {
  animation: 1s infinite bounce;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-moon {
  --tw-bg-opacity: 1;
  background-color: rgb(196 194 165 / var(--tw-bg-opacity));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue {
  --tw-gradient-to: #123648 var(--tw-gradient-to-position);
}

.object-fill {
  object-fit: fill;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-\[8px\] {
  padding-bottom: 8px;
}

.pl-\[5px\] {
  padding-left: 5px;
}

.pr-\[5px\] {
  padding-right: 5px;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-\[7px\] {
  padding-top: 7px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-lato {
  font-family: Lato, Arial, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[25px\] {
  font-size: 25px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.bg-dotted-spacing-1 {
  --tw-bg-dotted-spacing-x: .25rem;
  --tw-bg-dotted-spacing-y: .25rem;
  background-size: var(--tw-bg-dotted-spacing-x) var(--tw-bg-dotted-spacing-y);
}

.bg-dotted-black {
  --tw-bg-dotted-color: #000;
  --tw-bg-dotted-radius: 1px;
  background-image: radial-gradient(circle at center, var(--tw-bg-dotted-color) var(--tw-bg-dotted-radius), transparent 0);
}

.bg-dotted-radius-0\.5 {
  --tw-bg-dotted-radius: .125rem;
  background-image: radial-gradient(circle at center, var(--tw-bg-dotted-color) var(--tw-bg-dotted-radius), transparent 0);
}

@keyframes moonshine {
  0% {
    opacity: 0;
    box-shadow: 0 0 #c4c2a5;
  }

  10% {
    opacity: .1;
    box-shadow: 0 0 10px #c4c2a5;
  }

  20% {
    opacity: .2;
    box-shadow: 0 0 20px #c4c2a5;
  }

  30% {
    opacity: .3;
    box-shadow: 0 0 30px #c4c2a5;
  }

  40% {
    opacity: .4;
    box-shadow: 0 0 40px #c4c2a5;
  }

  50% {
    opacity: .5;
    box-shadow: 0 0 50px #c4c2a5;
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 100px #c4c2a5;
  }
}

.galaxy {
  background: #150d0b;
  background: -webkit-linear-gradient(to right, #150d0b, #654c21);
  background: linear-gradient(to right, #150d0b, #654c21);
}

.big-moon {
  -webkit-backface-visibility: hidden;
  background: #c7cbd0;
  border-radius: 0 0 10rem 10rem;
  width: 320px;
  height: 182px;
  box-shadow: inset -37.5px 0 #9098a1;
}

.big-moon li {
  background: #737277;
  border-radius: 50%;
  list-style: none;
  position: relative;
}

.big-moon li:first-child {
  width: 38px;
  height: 38px;
  top: 30px;
  left: 38px;
  box-shadow: inset 9px -3px #414043;
}

.big-moon li:nth-child(2) {
  width: 37px;
  height: 37px;
  top: 10px;
  left: 225px;
  box-shadow: inset 4px -1.5px #414043;
}

.big-moon li:nth-child(3) {
  width: 36px;
  height: 36px;
  top: 20px;
  left: 150px;
  box-shadow: inset 4px -1.5px #414043;
}

.big-moon li:nth-child(4) {
  width: 19px;
  height: 19px;
  top: -20px;
  left: 75px;
  box-shadow: inset 4px -1.2px #414043;
}

.big-moon li:nth-child(5) {
  width: 18px;
  height: 18px;
  top: -80px;
  left: 131px;
  box-shadow: inset 3px -1px #414043;
}

.big-moon li:nth-child(6) {
  width: 19px;
  height: 19px;
  top: -30px;
  left: 106px;
  box-shadow: inset 3px -1.2px #414043;
}

.big-moon li:nth-child(7) {
  width: 16px;
  height: 16px;
  top: -150px;
  left: 200px;
  box-shadow: inset 3px -1.2px #414043;
}

:root {
  --white: #f5f5f5;
  --lightgrey: #dadada;
  --midgrey: #b4b2b2;
  --darkgrey: #554842;
  --red: #f01a19;
  --darkred: #a75248;
}

.rocket {
  z-index: 100;
  width: 60px;
  padding-bottom: 45px;
  display: inline-block;
  position: sticky;
  top: 445px;
  left: 46%;
  transform: rotate(180deg);

  & .rocket-body {
    width: 40px;
    animation: .5s infinite bounce;
    left: calc(50% - 40px);

    & .body {
      background-color: var(--lightgrey);
      border-top: 5px solid var(--white);
      border-radius: 100% 100% 50% 50%;
      height: 90px;
      left: calc(50% - 40px);
    }
  }

  & .window {
    background-color: var(--darkred);
    border: 5px solid var(--midgrey);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: calc(50% - 12px);
  }

  & .fin {
    z-index: -100;
    background-color: var(--darkred);
    width: 25px;
    height: 27px;
    position: absolute;
  }

  & .fin-left {
    border-top-left-radius: 80%;
    border-bottom-left-radius: 20%;
    top: calc(100% - 27px);
    left: -15px;
  }

  & .fin-right {
    border-top-right-radius: 80%;
    border-bottom-right-radius: 20%;
    top: calc(100% - 27px);
    right: -15px;
  }

  & .exhaust-flame {
    background: linear-gradient(to bottom, transparent 10%, var(--white) 100%);
    width: 14px;
    height: 75px;
    animation: .2s infinite exhaust;
    position: absolute;
    top: 50%;
    left: calc(50% - 16px);
  }

  & .exhaust-fumes li {
    background-color: var(--white);
    border-radius: 100%;
    width: 30px;
    height: 10px;
    list-style: none;
    position: absolute;

    &:first-child {
      width: 20px;
      height: 20px;
      animation: 3s infinite fumes;
      bottom: -70px;
    }

    &:nth-child(2) {
      width: 20px;
      height: 25px;
      animation: 3.2s infinite fumes;
      top: 120px;
      left: -20px;
    }

    &:nth-child(3) {
      width: 60px;
      height: 60px;
      animation: 3s 1s infinite fumes;
      top: 105px;
      left: -20px;
    }

    &:nth-child(4) {
      width: 50px;
      height: 50px;
      animation: 4s 2s infinite fumes;
      top: 105px;
      left: -65px;
    }

    &:nth-child(5) {
      width: 65px;
      height: 65px;
      animation: 5s infinite fumes;
      top: 115px;
      left: -30px;
    }

    &:nth-child(6) {
      width: 50px;
      height: 50px;
      animation: 4s infinite fumes2;
      top: 140px;
      left: -20px;
    }

    &:nth-child(7) {
      width: 50px;
      height: 50px;
      animation: 3s infinite fumes;
      top: 110px;
      left: 30px;
    }

    &:nth-child(8) {
      width: 60px;
      height: 60px;
      top: 110px;
      left: 0;
    }

    &:nth-child(9) {
      width: 45px;
      height: 45px;
      animation: 4s infinite fumes;
      top: 110px;
      left: 60px;
    }
  }
}

@keyframes fumes {
  50% {
    background-color: #0000;
    transform: scale(1.5);
  }

  51% {
    transform: scale(.8);
  }

  100% {
    background-color: var(--white);
    transform: scale(1);
  }
}

@keyframes exhaust {
  0% {
    background: linear-gradient(to bottom, transparent 10%, var(--white) 100%);
  }

  50% {
    background: linear-gradient(to bottom, transparent 8%, var(--white) 100%);
  }

  75% {
    background: linear-gradient(to bottom, transparent 12%, var(--white) 100%);
  }
}

@keyframes fumes2 {
  50% {
    transform: scale(1.1);
  }
}

.project-item {
  opacity: .1;
}

.moon-surface {
  background: url("bg-moon-surface.66388038.jpg") repeat-x;
}

.moon-crescent {
  background: #121210;
}

.canvas-section {
  width: 100%;
  height: 100px;
  position: relative;
  overflow: hidden;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.star-background {
  background-color: #000;
  background-image: radial-gradient(circle at 10% 15%, #ffffffe6 1px, #0000 1px), radial-gradient(circle at 25% 30%, #fffc 2px, #0000 2px), radial-gradient(circle at 50% 60%, #ffffffb3 1.5px, #0000 1.5px), radial-gradient(circle at 75% 40%, #fff9 1px, #0000 1px), radial-gradient(circle at 90% 75%, #fffc 2px, #0000 2px), radial-gradient(circle at 20% 80%, #ffffff80 1.5px, #0000 1.5px), radial-gradient(circle at 60% 10%, #ffffffe6 1.5px, #0000 1.5px), radial-gradient(circle at 80% 90%, #fff9 1px, #0000 1px), radial-gradient(circle at 40%, #ffffffb3 2px, #0000 2px), radial-gradient(circle at 65% 85%, #ffffff80 1px, #0000 1px), radial-gradient(circle at 15% 95%, #fffc 2px, #0000 2px), radial-gradient(circle at 90% 30%, #ffffffb3 1.5px, #0000 1.5px), radial-gradient(circle at 35% 70%, #fff9 2px, #0000 2px), radial-gradient(circle at 55% 45%, #ffffffe6 1px, #0000 1px);
  background-position: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: local;
  background-origin: padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box;
  position: relative;
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.star-background:before {
  content: "";
  opacity: .6;
  background-color: #0000;
  background-image: radial-gradient(circle at 30% 20%, #fffc 2px, #0000 2px), radial-gradient(circle at 45% 60%, #fff9 1.5px, #0000 1.5px), radial-gradient(circle at 70% 10%, #ffffffb3 1px, #0000 1px), radial-gradient(circle at 85% 90%, #ffffffe6 2px, #0000 2px), radial-gradient(circle at 15%, #fffc 1.5px, #0000 1.5px);
  background-position: 0 0, 0 0, 0 0, 0 0, 0 0;
  background-repeat: repeat, repeat, repeat, repeat, repeat;
  background-size: 100% 100%;
  background-attachment: scroll, scroll, scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box, border-box;
  animation: 3s infinite alternate twinkle;
  position: absolute;
  inset: 0;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.group:hover .group-hover\:animate-moonshine {
  animation: 1s linear forwards moonshine;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

@media not all and (width >= 768px) {
  .max-md\:-left-\[35px\] {
    left: -35px;
  }

  .max-md\:-ml-\[150px\] {
    margin-left: -150px;
  }

  .max-md\:-ml-\[35px\] {
    margin-left: -35px;
  }

  .max-md\:hidden {
    display: none;
  }
}

@media (width >= 480px) {
  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-\[230px\] {
    width: 230px;
  }

  .sm\:basis-1\/2, .sm\:basis-2\/4 {
    flex-basis: 50%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }
}

@media (width >= 768px) {
  .md\:-mt-32 {
    margin-top: -8rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:h-\[230px\] {
    height: 230px;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 976px) {
  .lg\:basis-1\/3 {
    flex-basis: 33.3333%;
  }

  .lg\:basis-1\/6 {
    flex-basis: 16.6667%;
  }

  .lg\:basis-2\/6 {
    flex-basis: 33.3333%;
  }
}

@media (width >= 1440px) {
  .xl\:p-12 {
    padding: 3rem;
  }
}
/*# sourceMappingURL=index.c7961265.css.map */
