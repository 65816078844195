@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100&family=Nova+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes moonshine {
  0% {
    box-shadow: 0 0 0 #c4c2a5;
    opacity: 0;
  }

  10% {
    box-shadow: 0 0 10px #c4c2a5;
    opacity: 0.1;
  }

  20% {
    box-shadow: 0 0 20px #c4c2a5;
    opacity: 0.2;
  }

  30% {
    box-shadow: 0 0 30px #c4c2a5;
    opacity: 0.3;
  }

  40% {
    box-shadow: 0 0 40px #c4c2a5;
    opacity: 0.4;
  }

  50% {
    box-shadow: 0 0 50px #c4c2a5;
    opacity: 0.5;
  }

  100% {
    box-shadow: 0 0 100px #c4c2a5;
    opacity: 1;
  }
}

.galaxy {
  background: #150D0B;
  background: -webkit-linear-gradient(to right, #150D0B, #654C21);
  background: linear-gradient(to right, #150D0B, #654C21);
}

.big-moon {
  width: 320px;
  height: 182px;
  background: #c7cbd0;
  border-radius: 0 0 10rem 10rem;
  box-shadow: inset -37.5px 0 0 0 #9098a1;
  -webkit-backface-visibility: hidden;
}

.big-moon li {
  position: relative;
  list-style: none;
  background: #737277;
  border-radius: 50%;
}

.big-moon li:nth-child(1) {
  left: 38px;
  top: 30px;
  width: 38px;
  height: 38px;
  box-shadow: inset 9px -3px 0 0 #414043;
}

.big-moon li:nth-child(2) {
  left: 225px;
  top: 10px;
  width: 37px;
  height: 37px;
  box-shadow: inset 4px -1.5px 0 0 #414043;
}

.big-moon li:nth-child(3) {
  left: 150px;
  top: 20px;
  width: 36px;
  height: 36px;
  box-shadow: inset 4px -1.5px 0 0 #414043;
}

.big-moon li:nth-child(4) {
  left: 75px;
  top: -20px;
  width: 19px;
  height: 19px;
  box-shadow: inset 4px -1.2px 0 0 #414043;
}

.big-moon li:nth-child(5) {
  left: 131px;
  top: -80px;
  width: 18px;
  height: 18px;
  box-shadow: inset 3px -1px 0 0 #414043;
}

.big-moon li:nth-child(6) {
  left: 106px;
  top: -30px;
  width: 19px;
  height: 19px;
  box-shadow: inset 3px -1.2px 0 0 #414043;
}

.big-moon li:nth-child(7) {
  left: 200px;
  top: -150px;
  width: 16px;
  height: 16px;
  box-shadow: inset 3px -1.2px 0 0 #414043;
}

:root {
  --white: #f5f5f5;
  --lightgrey: #dadada;
  --midgrey: #b4b2b2;
  --darkgrey: #554842;
  --red: #f01a19;
  --darkred: #a75248;
}

.rocket {
  padding-bottom: 45px;
  width: 60px;
  position: sticky;
  top: 445px;
  left: 46%;
  z-index: 100;
  display: inline-block;
  transform: rotate(180deg);

  .rocket-body {
    width: 40px;
    left: calc(50% - 40px);
    animation: bounce 0.5s infinite;

    .body {
      background-color: var(--lightgrey);
      height: 90px;
      left: calc(50% - 40px);
      border-top-right-radius: 100%;
      border-top-left-radius: 100%;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-top: 5px solid var(--white);
    }
  }

  .window {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--darkred);
    left: calc(50% - 12px);
    top: 20px;
    border: 5px solid var(--midgrey);
  }

  .fin {
    position: absolute;
    z-index: -100;
    height: 27px;
    width: 25px;
    background-color: var(--darkred);
  }

  .fin-left {
    left: -15px;
    top: calc(100% - 27px);
    border-top-left-radius: 80%;
    border-bottom-left-radius: 20%;
  }

  .fin-right {
    right: -15px;
    top: calc(100% - 27px);
    border-top-right-radius: 80%;
    border-bottom-right-radius: 20%;
  }

  .exhaust-flame {
    position: absolute;
    top: 50%;
    width: 14px;
    background: linear-gradient(to bottom, transparent 10%, var(--white) 100%);
    height: 75px;
    left: calc(50% - 16px);
    animation: exhaust 0.2s infinite;
  }

  .exhaust-fumes li {
    width: 30px;
    height: 10px;
    background-color: var(--white);
    list-style: none;
    position: absolute;
    border-radius: 100%;

    &:first-child {
      width: 20px;
      height: 20px;
      bottom: -70px;
      animation: fumes 3s infinite;
    }

    &:nth-child(2) {
      width: 20px;
      height: 25px;
      left: -20px;
      top: 120px;
      animation: fumes 3.2s infinite;
    }

    &:nth-child(3) {
      width: 60px;
      height: 60px;
      left: -20px;
      top: 105px;
      animation: fumes 3s 1s infinite;
    }

    &:nth-child(4) {
      width: 50px;
      height: 50px;
      left: -65px;
      animation: fumes 4s 2s infinite;
      top: 105px;
    }

    &:nth-child(5) {
      width: 65px;
      height: 65px;
      left: -30px;
      top: 115px;
      animation: fumes 5s infinite;
    }

    &:nth-child(6) {
      width: 50px;
      height: 50px;
      left: -20px;
      top: 140px;
      animation: fumes2 4s infinite;
    }

    &:nth-child(7) {
      width: 50px;
      height: 50px;
      left: 30px;
      top: 110px;
      animation: fumes 3s infinite;
    }

    &:nth-child(8) {
      width: 60px;
      height: 60px;
      left: 0;
      top: 110px;
    }

    &:nth-child(9) {
      width: 45px;
      height: 45px;
      left: 60px;
      top: 110px;
      animation: fumes 4s infinite;
    }
  }
}

@keyframes fumes {
  50% {
    transform: scale(1.5);
    background-color: transparent;
  }
  51% {
    transform: scale(0.8);
  }
  100% {
    background-color: var(--white);
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  50% {
    transform: translate3d(0px, -4px, 0);
  }
  100% {
    transform: translate3d(0px, 0px, 0);
  }
}

@keyframes exhaust {
  0% {
    background: linear-gradient(to bottom, transparent 10%, var(--white) 100%);
  }
  50% {
    background: linear-gradient(to bottom, transparent 8%, var(--white) 100%);
  }
  75% {
    background: linear-gradient(to bottom, transparent 12%, var(--white) 100%);
  }
}

@keyframes fumes2 {
  50% {
    transform: scale(1.1);
  }
}

.project-item {
  opacity: 0.1;
}

.moon-surface {
  background: url("../img/bg/bg-moon-surface.jpg") repeat-x;
}

.moon-crescent {
  background: #121210;
}

.canvas-section {
  position: relative;
  height: 100px;
  width: 100%;
  overflow: hidden;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.star-background {
  position: relative;
  background:
    radial-gradient(circle at 10% 15%, rgba(255, 255, 255, 0.9) 1px, transparent 1px),
    radial-gradient(circle at 25% 30%, rgba(255, 255, 255, 0.8) 2px, transparent 2px),
    radial-gradient(circle at 50% 60%, rgba(255, 255, 255, 0.7) 1.5px, transparent 1.5px),
    radial-gradient(circle at 75% 40%, rgba(255, 255, 255, 0.6) 1px, transparent 1px),
    radial-gradient(circle at 90% 75%, rgba(255, 255, 255, 0.8) 2px, transparent 2px),
    radial-gradient(circle at 20% 80%, rgba(255, 255, 255, 0.5) 1.5px, transparent 1.5px),
    radial-gradient(circle at 60% 10%, rgba(255, 255, 255, 0.9) 1.5px, transparent 1.5px),
    radial-gradient(circle at 80% 90%, rgba(255, 255, 255, 0.6) 1px, transparent 1px),
    radial-gradient(circle at 40% 50%, rgba(255, 255, 255, 0.7) 2px, transparent 2px),
    radial-gradient(circle at 65% 85%, rgba(255, 255, 255, 0.5) 1px, transparent 1px),
    radial-gradient(circle at 15% 95%, rgba(255, 255, 255, 0.8) 2px, transparent 2px),
    radial-gradient(circle at 90% 30%, rgba(255, 255, 255, 0.7) 1.5px, transparent 1.5px),
    radial-gradient(circle at 35% 70%, rgba(255, 255, 255, 0.6) 2px, transparent 2px),
    radial-gradient(circle at 55% 45%, rgba(255, 255, 255, 0.9) 1px, transparent 1px);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: local;
  background-color: #000; /* Adjust background color */
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.star-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:
    radial-gradient(circle at 30% 20%, rgba(255, 255, 255, 0.8) 2px, transparent 2px),
    radial-gradient(circle at 45% 60%, rgba(255, 255, 255, 0.6) 1.5px, transparent 1.5px),
    radial-gradient(circle at 70% 10%, rgba(255, 255, 255, 0.7) 1px, transparent 1px),
    radial-gradient(circle at 85% 90%, rgba(255, 255, 255, 0.9) 2px, transparent 2px),
    radial-gradient(circle at 15% 50%, rgba(255, 255, 255, 0.8) 1.5px, transparent 1.5px);
  background-size: 100% 100%;
  animation: twinkle 3s infinite alternate;
  opacity: 0.6;
}
